@import "../styles/colors";

.title {
  text-align: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid gray;
}

.name {
  color: $main;
}
