@import "./colors";

.welcome {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .players {
    display: flex;
    align-items: center;

    input {
      padding: 1rem;
      font-size: 1.5rem;
      border: 0;
      background-color: transparent;
      border-bottom: 1px solid gray;
    }

    .vs {
      margin: 0 3rem;
      font-size: 1.5rem;
    }
  }
}
