@import "./styles/_colors";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-color: $bg-color;
  padding: 0 11rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.alert {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

button {
  padding: 0.75rem;
  font-size: 1.5rem;
  margin: 2.5rem 0;
  background-color: transparent;
  border: 3px solid black;
  border-radius: 5px;
  cursor: pointer;

  &:focus {
    outline: 3px dotted gray;
  }

  &:hover {
    background-color: black;
    color: $bg-color;
  }
}
