@import "./colors";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    padding: 1rem 0;
  }

  .board {
    background-color: #ebcba3;
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
