@import "../styles/colors";

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid $bg-color;
  cursor: pointer;
  height: 100px;
  width: 100px;

  img {
    width: 50px;
  }
}
